import React from 'react'
import { CookieFormStyles } from './CookieForm.styles'
import { useCookies } from 'react-cookie'

function CookieForm({ setCookie }) {
  return (
    <CookieFormStyles className='shadow bg-primary d-flex align-items-center justify-content-center slide-top'>
      <div className="row p-4">
        <div className="col-12 col-md-8">
          <h3 className='h4 text-white mb-2 mobile-title'>Datenschutzeinstellungen</h3>
          <p className='text-white mobile-text'>
            Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu freiwilligen Diensten geben möchten, müssen Sie Ihre Erziehungsberechtigten um Erlaubnis bitten. Wir verwenden Cookies und andere Technologien auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), z. B. für personalisierte Anzeigen und Inhalte oder Anzeigen- und Inhaltsmessung. Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer Datenschutzerklärung. Hier finden Sie eine Übersicht über alle verwendeten Cookies. Sie können Ihre Einwilligung zu ganzen Kategorien geben oder sich weitere Informationen anzeigen lassen und so nur bestimmte Cookies auswählen.
          </p>
        </div>
        <div className="col-12 col-md-4">
          <div className="d-flex align-items-center justify-content-center gap-2 flex-column h-100">
            <button className='w-100 btn btn-success btn-sm' onClick={() => setCookie('custos-cookies', 'alle_akzeptieren')}>Alle akzeptieren</button>
            <button className='w-100 btn btn-light btn-sm' onClick={() => setCookie('custos-cookies', 'speichern')}>Speichern</button>
            <button className='w-100 btn btn-light btn-sm' onClick={() => setCookie('custos-cookies', 'nur_essenzielle_cookies_akzeptieren')}>Nur essenzielle Cookies akzeptieren</button>
          </div>
        </div>
      </div>
    </CookieFormStyles>
  )
}

export default CookieForm