import React, { useEffect } from 'react'
import userImg from '../assets/user.jpg'
import ArticleCard from '../components/ArticleCard'
import { useSelector } from 'react-redux'
import Spinner from '../components/Spinner'
import { useParams } from 'react-router-dom'
import { fetchAuthorArticles, resetAllArticlesAuthor } from '../features/articles/articleActions'
import { REACT_APP_BACKEND_STORAGE_URL } from '../config'
import Pagination from '../components/Pagination'

function AuthorPage() {
  const { loading, author, articles, page, totalPages } = useSelector(store => store.articles.author_articles)

  const { username } = useParams()

  useEffect(() => {
    fetchAuthorArticles(username)

    return () => {
      resetAllArticlesAuthor()
    }
  }, [username])

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="container my-5">
      <div className='d-flex align-items-center justify-content-start gap-3 my-4'>
        {author.profile_image === null ?
          <div className='bg-primary rounded-3 text-white d-flex align-items-center justify-content-center text-uppercase fw-bold' style={{ width: '50px', height: '50px' }}>
            {author.name.slice(0, 1) + author.surname.slice(0, 1)}
          </div>
          : <img src={REACT_APP_BACKEND_STORAGE_URL + '/' + author.profile_image} alt="" width={50} className='img-fluid rounded-2' />}
        <h1 className='mb-0'>{author.name + ' ' + author.surname}</h1>
      </div>
      <div className="row mt-3 g-3">
        <div className="col-12">
          <h5>AKTUELLE BEITRÄGE</h5>
        </div>
        {articles.map((article, index) => (
          <div className="col-12 col-md-12 col-xl-12" key={index}>
            <ArticleCard article={article} />
          </div>
        ))}
        {articles.length === 0 && <div className="col-12 col-md-12 col-xl-12" >
          <div className="alert alert-primary">
            Keine Artikel gefunden
          </div>
        </div>}
        {articles !== 0 && totalPages !== 1 ? <div className="col-12">
          <Pagination page={page} last_page={totalPages} collectData={fetchAuthorArticles} username={username} />
        </div> : ''}
      </div>
    </div>
  )
}

export default AuthorPage