import React, { useEffect, useState } from 'react'
import bgOne from '../../assets/switch-1.webp'
import bgTwo from '../../assets/switch-2.webp'
import { useLocation } from 'react-router-dom'

function OurServices() {
  const [selected, setSelected] = useState(1)
  const location = useLocation()
  const selectValue = (val) => {
    setSelected(val)
  }

  useEffect(() => {
    if (location !== null) {
      if (location.hash === '#servicesB') {
        setSelected(2)
      }

      if (location.hash === '#servicesA') {
        setSelected(1)
      }
    }
  }, [location])

  return (
    <div className='container container-width my-4'>
      <h4 className='mb-4 mt-4 text-center fw-medium'>Unsere Mission</h4>
      <div className="row">
        <div className="col-12 col-md-12 col-xl-12">
          <p className='text-center lh-sm mb-5 width-custom mx-auto'>
            Unsere Dienstleistung ermöglicht Versicherungsnehmern, ihre Forderungen gegenüber Versicherungsgesellschaften auf Augenhöhe
            geltend zu machen. Zudem fördern wir die Entwicklung des Sachversicherungsrechts. Strittige Fragestellungen, die sonst keiner Klärung
            zugeführt werden, werden entschieden. Der in der Praxis üblichen Rechtsprechungsvermeidung durch Vergleiche und Anerkenntnisse
            werden konkrete Urteile entgegengesetzt. So schaffen wir Rechtssicherheit.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div id="servicesA"></div>
          <div id="servicesB"></div>
          <div className="d-flex align-items-center justify-content-center mt-4">
            <button className={`btn ${selected === 1 ? 'btn-success text-white' : 'btn-outline-success'} btn-md-lg mx-2 border-2 fw-medium`} onClick={() => selectValue(1)}>
              Strittige Forderungen
            </button>
            <button className={`btn ${selected === 2 ? 'btn-success text-white' : 'btn-outline-success'} btn-md-lg mx-2 border-2 fw-medium`} onClick={() => selectValue(2)}>
              Prozessfinanzierung
            </button>
          </div>
        </div>
        {selected === 1 && <div className="col-12 mt-5 bg-light px-0 rounded-3 shadow">
          <div className='d-flex align-items-center justify-content-center'>
            <div className='width-custom px-3 px-md-5'>
              <p>
                Nicht selten kommt es vor, dass Versicherer berechtige Ansprüche aus
                Versicherungsverträgen ablehnen oder die Entschädigungsleistung
                unberechtigt kürzen. Die Obergerichtliche und Höchstrichterliche
                Rechtsprechung findet nicht immer ihren Weg in die Praxis.
                Sachverhalte werden unterschiedlich beurteilt und dokumentiert und
                Beweislasten unterschiedlich angewendet. Versicherungsnehmer stehen
                dann vor der Entscheidung, die Regulierung des Versicherers zu
                akzeptieren oder ein Gerichtsverfahren anzustrengen.
                Entscheidungsrelevante Kriterien sind häufig das Kostenrisiko,
                Wirtschaftlichkeitsüberlegungen, Respekt vor der psychischen und
                emotionalen Belastung, Unsicherheit über die Erfolgsaussichten und
                Annahmen über den zeitlichen Aufwand. Häufig entscheiden sich
                Versicherungsnehmer gegen eine Verfolgung ihrer Ansprüche.
              </p>
            </div>
            <div className='width-compentence d-none d-lg-block'>
              <img src={bgOne} alt="Für Versicherungsnehmer" className='img-fluid rounded-end' />
            </div>
          </div>
        </div>}
        {selected === 2 && <div className="col-12 mt-5 bg-light px-0 shadow">
          <div className='d-flex align-items-center justify-content-center'>
            <div className='width-custom px-3 px-md-5'>
              <p>
                Für Versicherungsnehmer, die aus wirtschafltichen Gründen Ihre
                Ansprüche nicht selbst verfolgen können oder wollen, übernehmen
                wir die außergerichtliche und gerichtliche Geltendmachung auf Basis
                individueller Vereinbarungen. Wir übernehmen gegen eine
                Erfolgsbeteiligung die Verfahrenskosten. Nach individueller Prüfung
                bieten wir Unterstützung in Fällen, in denen Versicherungsnehmer
                aus wirtschaftlichen oder sonstigen Fällen gezwungen sind,
                Vergleichsvorschläge über Beträge anzunehmen, die der Versicherer
                nur dann leistet, wenn ein Vergleich tatsächlich zustande kommt. Hier
                bieten wir individuelle Alternativen, indem der Versicherungsnehmer
                so gestellt wird, dass seine wirtschaftlichen Nöte berücksichtigt
                werden, der Anspruch aber trotzdem in voller Höhe geltend gemacht
                werden kann. Solche Forderungen werden auch interessierten
                Kapitalgebern unter Beteiligung am Erfolg zum Kauf angeboten.
              </p>
            </div>
            <div className='width-compentence d-none d-lg-block'>
              <img src={bgTwo} alt="" className='img-fluid' />
            </div>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default OurServices