import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  articles: {
    loading: true,
    error: null,
    articles: [],
    page: 1,
    totalPages: 0,
  },
  article: {
    loading: true,
    error: null,
    article: null,
  },
  author_articles: {
    loading: true,
    error: null,
    articles: [],
    author: null,
    page: 1,
    totalPages: 0,
  },
};

export const articleSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    getArticlesPending: (state) => {
      state.articles.loading = true;
      state.articles.error = null;
    },
    getArticlesRejected: (state, { payload }) => {
      state.articles.loading = false;
      state.articles.error = payload;
    },
    getArticlesSuccess: (state, { payload }) => {
      state.articles.loading = false;
      state.articles.articles = payload.data;
      state.articles.page = payload.current_page;
      state.articles.totalPages = payload.last_page;
    },
    resetArticles: (state) => {
      state.articles = { ...initialState.articles };
    },
    getArticlePending: (state) => {
      state.article.loading = true;
      state.article.error = null;
    },
    getArticleRejected: (state, { payload }) => {
      state.article.loading = false;
      state.article.error = payload;
    },
    getArticleSuccess: (state, { payload }) => {
      state.article.loading = false;
      state.article.article = payload;
    },
    getAuthorArticlesPending: (state) => {
      state.author_articles.loading = true;
      state.author_articles.error = null;
    },
    getAuthorArticlesRejected: (state, { payload }) => {
      state.author_articles.loading = false;
      state.author_articles.error = payload;
    },
    getAuthorArticlesSuccess: (state, { payload }) => {
      state.author_articles.loading = false;
      state.author_articles.articles = payload.relatedArticles.data;
      state.author_articles.author = payload.author;
      state.author_articles.page = payload.relatedArticles.current_page;
      state.author_articles.totalPages = payload.relatedArticles.last_page;
    },
    resetAuthorArticles: (state) => {
      state.author_articles = { ...initialState.author_articles };
    },
  },
});
