import Layout from './layouts/Layout';
import logo from './logo.svg';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Information from './pages/Information';
import Imprint from './pages/Imprint';
import Privacy from './pages/Privacy';
import Articles from './pages/Articles';
import SingleArticle from './pages/SingleArticle';
import AuthorPage from './pages/AuthorPage';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Home />
      },
      {
        path: "datenschutz",
        element: <Information />
      },
      {
        path: 'impressum',
        element: <Imprint />
      },
      {
        path: 'cookie-einstellungen-andern',
        element: <Privacy />
      },
      {
        path: '/blog',
        element: <Articles />
      },
      {
        path: '/blog/:slug',
        element: <SingleArticle />
      },
      {
        path: '/blog/author/:username',
        element: <AuthorPage />
      }
    ]
  }
])

function App() {
  return <RouterProvider router={router} />
}

export default App;
