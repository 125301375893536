import styled from "styled-components";
import bgImg from "../assets/blog-image.webp";

export const BlogHeaderStyles = styled.div`
  background: url(${bgImg}) center center/cover no-repeat;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(11, 39, 81, 0.5);
    z-index: 1;
  }
`;


export const ArticleImage = styled.img`

  @media (max-width: 768px) {
    width: unset !important;
  }

`