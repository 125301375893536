import React from 'react'
import { Link } from 'react-router-dom'
import { REACT_APP_BACKEND_STORAGE_URL } from '../config'
import { ArticleImage } from '../pages/Articles.styles'


function ArticleCard({ article }) {
  return (
    <div className="card">
      <div className="d-flex align-items-stretch justify-content-start gap-3 flex-column flex-md-row">
        <ArticleImage src={REACT_APP_BACKEND_STORAGE_URL + '/' + article.cover_image} className="card-img-left object-fit-cover img-fluid" style={{ width: "300px" }} alt={article.title} />
        <div className="card-body">
          <div className="d-flex justify-content-between flex-column h-100">
            <div>
              <h5 className="card-title text-decoration-underline">
                <Link to={`/blog/${article.slug}`}>
                  {article.title}
                </Link>
              </h5>
              <p className="card-text">{article.content}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleCard