import styled from "styled-components";
import bgImg from "../assets/general-bg.webp";

export const HeaderCustomStyles = styled.div`
  min-height: 300px;
  height: 100%;
  position: relative;
  background: url(${bgImg}) center center/cover no-repeat;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(11, 39, 81, 0.4);
  }
`;
