import styled from "styled-components";
import backgroundImg from "../assets/bg-service.webp";

export const WhoAreWe = styled.div`
  background: url(${backgroundImg}) center center/cover no-repeat;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(11, 39, 81, 0.4);
    border-radius: 15px;
  }
`;
