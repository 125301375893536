import axios from "axios";
import { REACT_APP_BACKEND_API_URL } from "../../config";

const axe = axios.create();

axe.defaults.validateStatus = (status) => status < 500;

axe.defaults.baseURL = REACT_APP_BACKEND_API_URL;

export default axe;
