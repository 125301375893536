import React from 'react'

function Pagination({ page, last_page, collectData, username = null }) {
  const pages = [];
  for (let i = 1; i <= last_page; i++) {
    pages.push(i);
  }

  const handlePageChange = (newPage) => {
    if (username === null) {
      collectData(newPage)
    } else {
      collectData(username, newPage)
    }
  }


  return (
    <nav className='mt-3'>
      <ul className="pagination align-items-center justify-content-center">
        <li className={`page-item`} style={{ cursor: 'pointer' }} onClick={page === 1 ? () => { } : () => handlePageChange(page - 1)}>
          <div className={`page-link ${page === 1 ? 'text-muted' : ''}`}>Vorherige</div>
        </li>

        {pages.map((pageNumber) => (
          <li className={`page-item${pageNumber === page ? ' active' : ''}`} key={pageNumber} style={{ cursor: 'pointer' }} onClick={pageNumber === page ? () => { } : () => handlePageChange(pageNumber)}>
            <a className="page-link" href="#">{pageNumber}</a>
          </li>
        ))}

        <li className="page-item" style={{ cursor: 'pointer' }} onClick={last_page === page ? () => { } : () => handlePageChange(page + 1)}>
          <div className={`page-link ${page === last_page ? 'text-muted' : ''}`} href="#">Nächste</div>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination