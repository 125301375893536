import React, { useEffect } from 'react'
import { BlogHeaderStyles } from './Articles.styles'
import ArticleCard from '../components/ArticleCard'
import Spinner from '../components/Spinner'
import { useSelector } from 'react-redux'
import { fetchArticles, resetAllArticles } from '../features/articles/articleActions'
import Pagination from '../components/Pagination'

function Articles() {
  const { loading, articles, page, totalPages } = useSelector(store => store.articles.articles)

  useEffect(() => {
    fetchArticles()

    return () => {
      resetAllArticles()
    }
  }, [])

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <BlogHeaderStyles>
        <div className="container position-relative z-3">
          <h2 className='mb-0 text-white'>
            Der Blog
          </h2>
        </div>
      </BlogHeaderStyles>
      <div className="container my-5">
        <h5>AKTUELLE BEITRÄGE</h5>
        <div className="row mt-5 g-3">
          {articles.map((article, index) => (
            <div className="col-12 col-md-12 col-xl-12" key={index}>
              <ArticleCard article={article} />
            </div>
          ))}
          {articles.length === 0 && <div className="col-12 col-md-12 col-xl-12" >
            <div className="alert alert-primary">
              Keine Artikel gefunden
            </div>
          </div>}
          {articles.length !== 0 && totalPages !== 1 ? <div className="col-12">
            <Pagination page={page} last_page={totalPages} collectData={fetchArticles} />
          </div> : ''}
        </div>
      </div>
    </>
  )
}

export default Articles