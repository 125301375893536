import React from 'react'
import { FooterStyles } from './Footer.styles'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <FooterStyles className="container-fluid p-0 bg-light" id='contact'>
      <div className="container p-3 p-md-5 position-relative z-3">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-5 bg-primary rounded-3">
            <div className="p-4">
              <h6 className='mb-3 text-white'>SPRECHEN SIE UNS AN!</h6>
              <h6 className='mb-3 fw-bold text-white width-custom fs-6'>
                Sie haben Fragen, Interesse an einer
                Kooperation oder einen aktuellen Fall?
              </h6>
              <p className='text-white'>
                Custos GmbH <br />
                Sachversicherungsrecht<br />
                Anspruchsdurchsetzung<br />
                Prozessfinanzierung<br /><br />
                Sternstraße 7 <br />
                59269 Beckum <br /><br />
                Telefon: +49 (2521) 82449-20<br />
                E-Mail: info@custos.legal<br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-primary py-2 position-relative z-3'>
        <ul className='list-unstyled d-flex align-items-center justify-content-center gap-4'>
          <li className='py-2'>
            <Link to={'/datenschutz'} className='text-white'>
              Datenschutz
            </Link>
          </li>
          <li className='py-2'>
            <Link to={'/impressum'} className='text-white'>
              Impressum
            </Link>
          </li>
          <li className='py-2'>
            <Link to={'/cookie-einstellungen-andern'} className='text-white'>
              Cookie-Einstellungen ändern
            </Link>
          </li>
        </ul>
        <p className='text-center text-white mb-0 footer-copyright'>
          © {new Date().getFullYear()} Custos - Sachversicherungsrecht - Anspruchsdurchsetzung - Prozessfinanzierung
        </p>
      </div>
    </FooterStyles>
  )
}

export default Footer