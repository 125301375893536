import React, { useEffect } from 'react'
import { HeaderCustomStyles } from './Custom.styles';

function Information() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [])

  return (
    <>
      <HeaderCustomStyles className="container-fluid px-0" />
      <div className='container container-width my-5'>
        <h2>Datenschutz </h2>
        <h4>Datenschutzerklärung nach der DSGVO </h4>
        <h6>1. Name und Anschrift des für die Verarbeitung Verantwortlichen</h6>
        <p>
          Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist die:
          <br />
          Custos GmbH  <br />
          Sternstraße 7  <br />
          59269 Beckum  <br />
          Telefon: +49 2521 82449-20<br />
          E-Mail: info@custos.legal<br />
          Web: www.custos.legal <br />
        </p>
        <h6>2. Verwendung von Cookies </h6>
        <p>
          Die Internetseiten vom Unternehmen verwenden Cookies. Bei Cookies handelt es sich um Daten, die vom Internetbrowser auf dem Computersystem des Nutzers gespeichert werden. Die Cookies können beim Aufruf einer Seite an diese übermittelt werden und ermöglichen somit eine Zuordnung des Nutzers. Cookies helfen dabei, die Nutzung von Internetseiten für die Nutzer zu vereinfachen.
        </p>
        <p>
          Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.
        </p>
        <p>
          Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.
        </p>
        <p>
          Es ist jederzeit möglich der Setzung von Cookies durch entsprechende Änderung der Einstellung im Internetbrowser zu widersprechen. Gesetze Cookies können gelöscht werden. Es wird darauf hingewiesen, dass bei Deaktivierung von Cookies möglicherweise nicht alle Funktionen unserer Internetseite vollumfänglich genutzt werden können.
        </p>
        <h6>2. Verwendung von Cookies </h6>
        <p>
          Beim Aufruf der Internetseite werden durch ein automatisiertes System Daten und Informationen erfasst. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:
        </p>
        <ol>
          <li>
            Informationen über den Browsertyp und die verwendete Version
          </li>
          <li>
            Das Betriebssystem des Nutzers
          </li>
          <li>
            Den Internet-Service Provider des Nutzers
          </li>
          <li>
            Die IP-Adresse des Nutzers
          </li>
          <li>
            Datum und Uhrzeit des Zugriffs
          </li>
          <li>
            Webseiten, von denen das System des Nutzers auf unsere Internetseite gelangt (Referrer)
          </li>
          <li>
            Webseiten, die vom System des Nutzers über unsere Webseite aufgerufen werden Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
            <ul>
              <li>
                Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,
              </li>
              <li>
                Gewährleistung einer komfortablen Nutzung unserer Website,
              </li>
              <li>
                Auswertung der Systemsicherheit und -stabilität sowie
              </li>
              <li>
                zu weiteren administrativen Zwecken.
              </li>
            </ul>
          </li>
        </ol>
        <p>
          Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
        </p>
        <p>
          Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein. Nähere Erläuterungen dazu erhalten Sie unter den Ziff. 3 und 12 dieser Datenschutzerklärung. Die Daten der Logfiles werden dabei stets getrennt von anderen personenbezogenen Daten der Nutzer gespeichert.
        </p>
        <h6>4. Newsletter</h6>
        <p>
          Sofern Sie nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrücklich eingewilligt haben, verwenden wir Ihre E-Mail-Adresse dafür, Ihnen regelmäßig unseren Newsletter zu übersenden. Wird der Newsletter unseres Unternehmens abonniert, so werden die Daten in der jeweiligen Eingabemaske an den für die Verarbeitung Verantwortlichen übermittelt.
        </p>
        <p>
          Bei der Anmeldung zum Newsletter wird die IP-Adresse des Nutzers sowie Datum und Uhrzeit der Registrierung gespeichert. Dies dient dazu einen Missbrauch der Dienste oder der E-Mail-Adresse der betroffenen Person zu verhindern. Eine Weitergabe der Daten an Dritte erfolgt nicht. Eine Ausnahme besteht dann, wenn eine gesetzliche Verpflichtung zur Weitergabe besteht.
        </p>
        <p>
          Die Daten werden ausschließlich für den Versand des Newsletters verwendet. Das Abonnement des Newsletters kann durch die betroffene Person jederzeit gekündigt werden. Die Abmeldung ist jederzeit möglich, zum Beispiel über einen Link am Ende eines jeden Newsletters. Alternativ können Sie Ihren Abmeldewunsch gerne auch jederzeit an info@custos-gmbh.de per E-Mail senden.
        </p>
        <p>
          Ebenso kann die Einwilligung in die Speicherung der personenbezogenen Daten jederzeit widerrufen werden.
        </p>
        <h6>5. Möglichkeiten zur Kontaktaufnahme </h6>
        <p>
          Eine Kontaktaufnahme ist über die bereitgestellte E-Mail-Adresse möglich. Nimmt die betroffene Person über einen dieser Kanäle Kontakt mit dem für die Verarbeitung Verantwortlichen auf, so werden die von der betroffenen Person übermittelten personenbezogenen Daten automatisch gespeichert. Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.
        </p>
        <p>
          Die Speicherung dient allein zu Zwecken der Bearbeitung oder der Kontaktaufnahme zur betroffenen Person. Eine Weitergabe der Daten an Dritte findet nicht statt.
        </p>
        <h6>6. Routinemäßige Löschung und Sperrung von personenbezogenen Daten </h6>
        <p>
          Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene Daten der betroffenen Person nur so lange, wie dies zur Erreichung des Speicherungszwecks erforderlich ist. Eine Speicherung kann darüber hinaus dann erfolgen, soweit dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der für die Verarbeitung verantwortliche unterliegt, vorgesehen wurde.
        </p>
        <p>
          Sobald der Speicherungszweck entfällt oder eine durch die genannten Vorschriften vorgeschriebene Speicherfrist abläuft, werden die personenbezogenen Daten routinemäßig gesperrt oder gelöscht.
        </p>
        <h6>7. Rechte der betroffenen Person </h6>
        <p>
          Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:
        </p>
        <h6>7.1 Auskunftsrecht </h6>
        <p>
          Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.
        </p>
        <p>
          Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft verlangen:
        </p>
        <p>
          a) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden; <br />
          b) der Kategorie von personenbezogenen Daten, welche verarbeitet werden;<br />
          c) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;<br />
          d) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;<br />
          e) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;<br />
          f) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;<br />
          g) alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;<br />
          h) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.
        </p>
        <p>
          Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
        </p>
        <h6>7.2 Recht auf Berichtigung </h6>
        <p>
          Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
        </p>
        <h6>7.3 Recht auf Einschränkung der Verarbeitung </h6>
        <p>
          Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
        </p>
        <p>
          a) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;<br />
          b) die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;<br />
          c) der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder<br />
          d) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.
        </p>
        <p>
          Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
        </p>
        <p>
          Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
        </p>
        <h6>7.4 Recht auf Löschung </h6>
        <p>
          7.4.1. Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
        </p>
        <p>
          a) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.<br />
          b) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.<br />
          c) Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.<br />
          d) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.<br />
          e) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.<br />
          f) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
        </p>
        <p>
          7.4.2.Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt hat.
        </p>
        <p>
          7.4.3. Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist<br />

          a) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;<br />
          b) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;<br />
          c) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Ab. 3 DSGVO;<br />
          d) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das in Abs. 1 genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder<br />
          e) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
        </p>
        <h6>7.5 Recht auf Unterrichtung </h6>
        <p>
          Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.
        </p>
        <p>
          Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.
        </p>
        <h6>7.6 Recht auf Datenübertragbarkeit </h6>
        <p>
          Sie haben das das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
        </p>
        <p>
          a) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und<br />
          b) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
        </p>
        <p>
          In Ausübung dieses Rechts haben Sie ferner das Recht zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
        </p>
        <p>
          Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
        </p>
        <h6>7.7 Widerspruchsrecht </h6>
        <p>
          Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
        </p>
        <p>
          Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
        </p>
        <p>
          Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
        </p>
        <p>
          Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
        </p>
        <p>
          Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
        </p>
        <h6>7.8 Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h6>
        <p>
          Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
        </p>
        <h6>7.9 Automatisierte Entscheidung im Einzelfall einschließlich Profiling </h6>
        <p>
          Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche Wirkung entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
        </p>
        <p>
          a) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist, <br />
          b) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder <br />
          c) mit Ihrer ausdrücklichen Einwilligung erfolgt.
        </p>
        <p>
          Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
        </p>
        <p>
          Hinsichtlich der in a. und c. genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
        </p>
        <h6>7.10 Recht auf Beschwerde bei einer Aufsichtsbehörde </h6>
        <p>
          Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
        </p>
        <p>
          Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
        </p>
        <h6>8. Weitergabe der Daten an Dritte </h6>
        <p>
          Eine Weitergabe der Daten an Dritte erfolgt nicht. <br />
          Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
        </p>
        <ul>
          <li>
            Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,
          </li>
          <li>
            die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,
          </li>
          <li>
            für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie
          </li>
          <li>
            dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.
          </li>
        </ul>
        <h6>9. Rechtsgrundlage der Verarbeitung </h6>
        <p>
          Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Artikel 6 Absatz 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
        </p>
        <p>
          Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich sind, dient Artikel 6 Absatz 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
        </p>
        <p>
          Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, denen unser Unternehmen unterliegt, dient Artikel 6 Absatz 1 lit. c DSGVO als Rechtsgrundlage.
        </p>
        <p>
          Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Artikel 6 Absatz 1 lit. d DSGVO als Rechtsgrundlage.
        </p>
        <p>
          Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Artikel 6 Absatz 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung. Das berechtige Interesse unseres Unternehmens liegt in der Durchführung unserer Geschäftstätigkeit.
        </p>
        <h6>10. Google Analytics </h6>
        <p>
          Wir nutzen auf unserer Webseite Google Analytics, einen Webanalysedienst der Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
        </p>
        <p>
          Google Analytics verwendet sogenannte „Cookies“, Textdateien, die auf Ihrem Gerät gespeichert werden und die eine Analyse der Benutzung der durch Sie besuchten Webseiten ermöglichen. Google Analytics kann auch sogenannte Web Beacons (nicht sichtbare Grafiken) verwenden. Durch diese Web Beacons können Informationen wie der Besucherverkehr auf Webseiten ausgewertet werden. Die durch Cookies und Web Beacons erzeugten Informationen über die Benutzung unserer Webseite (einschließlich der IP-Adresse der Nutzer) werden an einen Server von Google, möglicherweise in den USA oder anderen Drittstaaten, übertragen und dort gespeichert. Diese Informationen können von Google an Vertragspartner von Google weitergegeben werden.
        </p>
        <p>
          Informationen zur vorhandenen Privacy-Shield-Zertifizierung von Google und weiteren relevanten Daten zur Datenverarbeitung durch Google im Rahmen der Nutzung der Google Dienste finden Sie in dieser Datenschutzerklärung unter dem Abschnitt „6) Informationen zu Google-Diensten“.
        </p>
        <p>
          Folgende Datenarten werden von Google verarbeitet:
        </p>
        <ul>
          <li>
            Online-Kennzeichnungen (einschließlich Cookie-Kennungen)
          </li>
          <li>
            IP-Adresse
          </li>
          <li>
            Gerätekennungen
          </li>
        </ul>
        <p>
          Darüber hinaus finden Sie weitere detaillierte Informationen zu den verarbeiteten Informationen unter https://www.google.com/intl/de/policies/privacy/#infocollect unter „Daten, die wir aufgrund Ihrer Nutzung unserer Dienste erhalten“, sowie unter https://privacy.google.com/businesses/adsservices/.
        </p>
        <p>
          Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung („anonymize IP“) ein. Hierdurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
        </p>
        <p>
          Weiterhin haben wir für den Einsatz von Google Analytics mit Google einen Vertrag zur Auftragsverarbeitung geschlossen (Art. 28 DSGVO). Google verarbeitet die Daten in unserem Auftrag, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten für uns zusammenzustellen und um weitere mit der Webseitennutzung und der Internetnutzung verbundene Dienstleistungen uns gegenüber zu erbringen. Google kann diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag von Google verarbeiten.
        </p>
        <p>
          Durch die Einbindung von Google Analytics verfolgen wir den Zweck, das Nutzerverhalten auf unserer Webseite zu analysieren und hierauf reagieren zu können. Dadurch können wir unser Angebot kontinuierlich verbessern.
        </p>
        <p>
          Rechtsgrundlage für die hier beschriebene Verarbeitung personenbezogener Daten ist Art. 6 Abs. 1 lit. f) DSGVO. Unser hierfür erforderliches berechtigtes Interesse liegt dabei in dem großen Nutzen, den die oben beschriebenen Funktionen für unser Angebot haben. Die statistische Auswertung des Nutzerverhaltens ermöglicht uns insbesondere eine interessengerechte Reaktion und Optimierung unseres Angebots.
        </p>
        <p>
          Im Rahmen der Auftragsverarbeitung ist Google berechtigt, Subunternehmer zu beauftragen. Eine Liste dieser Subunternehmer können Sie unter https://privacy.google.com/businesses/subprocessors/ finden.
        </p>
        <h6>11. Google Tag Manager </h6>
        <p>
          Auf unserer Webseite setzen wir den Google Tag Manager ein. Der Google Tag Manager ist eine Lösung von Google Inc., mit der wir unsere Website-Tags über eine Oberfläche verwalten können. Bei dem Google Tag Manager handelt es sich um eine cookielose Domain, die keine personenbezogenen Daten erfasst oder speichert. Der Google Tag Manager sorgt lediglich für die Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen. Der Google Tag Manager kann nicht auf diese Daten zugreifen. Sofern Sie auf Domain- oder Cookie-Ebene eine Deaktivierung durchgeführt haben, bleibt diese Deaktivierung für alle Tracking-Tags bestehen, die über den Google Tag Manager implementiert worden sind.
        </p>
        <h6>
          12. Bewerbungen
        </h6>
        <p>
          Wir verarbeiten die Daten, die Sie uns im Zusammenhang mit Ihrer Bewerbung zugesendet haben, um Ihre Eignung für die Stelle (oder ggf. andere offene Positionen in unseren Unternehmen) zu prüfen und das Bewerbungsverfahren durchzuführen.
        </p>
        <p>
          Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten in diesem Bewerbungsverfahren ist primär § 26 BDSG in der ab dem 25.05.2018 geltenden Fassung. Danach ist die Verarbeitung der Daten zulässig, die im Zusammenhang mit der Entscheidung über die Begründung eines Beschäftigungsverhältnisses erforderlich sind.
        </p>
        <p>
          Sollten die Daten nach Abschluss des Bewerbungsverfahrens ggf. zur Rechtsverfolgung erforderlich sein, kann eine Datenverarbeitung auf Basis der Voraussetzungen von Art. 6 DSGVO, insbesondere zur Wahrnehmung von berechtigten Interessen nach Art. 6 Abs. 1 lit. f) DSGVO erfolgen. Unser Interesse besteht dann in der Geltendmachung oder Abwehr von Ansprüchen.
        </p>
        <p>
          Daten von Bewerberinnen und Bewerbern werden im Falle einer Absage nach sechs Monaten gelöscht.
        </p>
        <p>
          Für den Fall, dass Sie einer weiteren Speicherung Ihrer personenbezogenen Daten zugestimmt haben, werden wir Ihre Daten in unseren Bewerber-Pool übernehmen. Dort werden die Daten nach Ablauf von zwei Jahren gelöscht.
        </p>
        <p>
          Sollten Sie im Rahmen des Bewerbungsverfahrens den Zuschlag für eine Stelle erhalten haben, werden die Daten aus dem Bewerberdatensystem in unser Personalinformationssystem überführt.
        </p>
        <h6>
          13. Datensicherheit
        </h6>
        <p>
          Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.
        </p>
        <p>
          Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
        </p>
        <h6>
          14. Dauer der Speicherung personenbezogener Daten
        </h6>
        <p>
          Personenbezogene Daten werden für die Dauer der jeweiligen gesetzlichen Aufbewahrungsfrist gespeichert. Nach Ablauf der Frist erfolgt eine routinemäßige Löschung der Daten, sofern nicht eine Erforderlichkeit für eine Vertragsanbahnung oder die Vertragserfüllung besteht.
        </p>
        <h6>15. Aktualität und Änderung dieser Datenschutzerklärung </h6>
        <p>
          Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai 2023.
          Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter www.custost-gmbh.de von Ihnen abgerufen und ausgedruckt werden.
        </p>
      </div>
    </>
  )
}

export default Information