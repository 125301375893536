import { faBars, faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import logo from '../../assets/logo.webp'
import { Link, useLocation } from 'react-router-dom'


const Modal = () => {
  return <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">Kontakt</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body my-3">
          <div className="col-12 col-md-6 col-lg-4">
            <ul className='list-unstyled'>
              <li className='py-2'>
                <FontAwesomeIcon icon={faMapMarkerAlt} className='text-primary me-1' /> <span>Custos GmbH,  Sternstraße 7, 59269 Beckum</span>
              </li>
              <li className='py-2'>
                <a href='tel:+4925218244920' className='text-decoration-none' target='_blank'>
                  <FontAwesomeIcon icon={faPhoneAlt} className='text-primary me-1' /> <span>+49 2521 82449-20</span>
                </a>
              </li>
              <li className='py-2'>
                <a href='mailto:info@custos.legal' className='text-decoration-none' target='_blank'>
                  <FontAwesomeIcon icon={faEnvelope} className='text-primary me-1' /> <span>info@custos.legal</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
        </div>
      </div>
    </div>
  </div>
}

function Navbar() {
  const location = useLocation()
  return (
    <>
      {Modal()}
      <div className="container-fluid pt-0 position-sticky top-0 start-0 end-0 navbar-index">
        <nav className="navbar navbar-expand-lg py-3">
          <div className="container-fluid">
            <Link className="navbar-brand text-white" to="/">
              <img src={logo} alt="Custos Logo" className='img-fluid mx-auto logo-style' />
            </Link>
            <button type="button" className="btn btn-primary d-lg-none ms-auto me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Kontakt
            </button>
            <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <FontAwesomeIcon icon={faBars} size='xl' color='black' />
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
              <ul className="navbar-nav me-3">
                <li className="nav-item">
                  <a className={`nav-link fw-medium fs-6 ${location.pathname === '/' && location.hash === '#company' ? 'text-primary' : ''}`} aria-current="page" href="/#company">Unternehmen</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link fw-medium fs-6 ${location.pathname === '/' && location.hash === '#servicesB' ? 'text-primary' : ''}`} href="/#servicesB">Prozessfinanzierung</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link fw-medium fs-6 ${location.pathname === '/' && location.hash === '#servicesA' ? 'text-primary' : ''}`} href="/#servicesA">
                    Strittige Forderungen
                  </a>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link fw-medium fs-6 ${location.pathname === '/blog' ? 'text-primary' : ''}`} to="/blog">Blog</Link>
                </li>
              </ul>
              <button type="button" className="btn btn-primary d-none d-lg-inline-block px-4" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Kontakt
              </button>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Navbar