import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { fetchArticle } from '../features/articles/articleActions'
import Spinner from '../components/Spinner'
import moment from 'moment'
import 'moment/locale/de'
import { REACT_APP_BACKEND_STORAGE_URL } from '../config'

function SingleArticle() {
  const { loading, article } = useSelector(store => store.articles.article)
  const { slug } = useParams()

  useEffect(() => {
    fetchArticle(slug)
  }, [slug])

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-center mt-3 mb-4">
        <img src={REACT_APP_BACKEND_STORAGE_URL + '/' + article.cover_image} alt={article.title} className='img-fluid' />
      </div>
      <h1>{article.title}</h1>
      <p>{moment(article.created_at).format("LL")} von <Link to={`/blog/author/${article.user.username}`}>{article.user.name + ' ' + article.user.surname}</Link></p>

      <p dangerouslySetInnerHTML={{ __html: article.content }} />

      <div className='mt-4'>
        {article.pdf_file !== null &&
          <>
            <object data={REACT_APP_BACKEND_STORAGE_URL + '/' + article.pdf_file} type="application/pdf" width="100%" height="700px">
              <p>Alternativer Text – fügen Sie einen Link ein <a href={REACT_APP_BACKEND_STORAGE_URL + '/' + article.pdf_file}> zum PDF!</a></p>
            </object>
            <a href={REACT_APP_BACKEND_STORAGE_URL + '/' + article.pdf_file} target='_blank' className='btn btn-primary mt-2 mb-4'>PDF Herunterladen</a>
          </>
        }

        <h3 className='mt-5'>Wir helfen Ihnen bei strittigen Auslegungsfragen</h3>
        <p>
          <b>Wir von Custos</b> kümmern uns im Schadenfall um Ihre Interessen als Versicherungsnehmer. Wir unterstützen Makler bei der professionellen Beratung ihrer Kunden. Sie profitieren von unserem Fachwissen und unserer Erfahrung. Wir machen uns Gedanken, die Ihnen nützen und vertreten Ihre Positionen. <b>Wir berücksichtigen bei den Diskussionen mit den Versicherern die Interessen der Versicherungsnehmer, auf die es auch bei der Auslegung ankommt.</b> Das vergisst die Regulierungspraxis gerne. Genauso wie die Tatsache, dass unzureichend (nicht eindeutig, gegen den Vertragszweck verstoßend) formulierte Bedingungen <b>zu Lasten des Versicherers gehen.</b>
        </p>
        <button className='btn btn-primary mt-2 mb-5'>Gespräch vereinbaren</button>
      </div>
    </div>
  )
}

export default SingleArticle