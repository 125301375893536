import styled from "styled-components";

export const CookieFormStyles = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  z-index: 999999;

  @media (max-width: 768px) {
    .mobile-text {
      font-size: 12px;
    }

    .mobile-title {
      font-size: 14px;
    }
  }

  &.slide-top {
    -webkit-animation: slide-top 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
    animation: slide-top 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
  }

  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(250px);
      transform: translateY(250px);
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(250px);
      transform: translateY(250px);
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }
`;
