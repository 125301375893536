import styled from "styled-components";
import footerBg from "../../assets/footer-bg.webp";

export const FooterStyles = styled.footer`
  background: url(${footerBg}) center center/cover no-repeat;
  position: relative;

  &::before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(11, 39, 81, 0.3);
    position: absolute;
    z-index: 1;
  }
  .underline::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    width: 50px;
    background-color: #0b2751;
  }

  img {
    width: 250px;
  }

  @media (max-width: 550px) {
    img {
      width: 150px;
      margin: 0 auto;
      display: table;
      margin-bottom: 30px;
    }

    .footer-copyright {
      font-size: 12px;
    }
  }

  @media (max-width: 850px) {
    img {
      width: 200px;
      margin: 0 auto;
      display: table;
      margin-bottom: 30px;
    }

    .footer-copyright {
      font-size: 12px;
    }
  }
`;
