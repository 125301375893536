import React from 'react'
import { SpinnerStyles } from './Spinner.styles'

function Spinner() {
  return (
    <SpinnerStyles>
      <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </SpinnerStyles>
  )
}

export default Spinner