import React, { useEffect } from 'react'
import Header from '../components/home/Header'
import OurServices from '../components/home/OurServices'
import { WhoAreWe } from './Home.styles'
import { useLocation } from "react-router";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faCircleInfo, faStar } from '@fortawesome/free-solid-svg-icons';

function Home() {
  const location = useLocation()

  useEffect(() => {
    const elementId = location.hash.substring(1);
    scrollToElement(elementId);
  }, [location]);

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <>
      <Header />
      <div className="container container-width my-4">
        <div className="row" id='company'>
          {/* <div className="col-12">
            <h4 className='text-center mb-4 mt-4 text-primary fw-semibold'>DURCHSETZUNG STRITTIGER ANSPRÜCHE AUS VERSICHERUNGSVERTRÄGEN</h4>
            <div className="d-flex align-items-center justify-content-center">
              <video controls src="https://www.custos-gmbh.de/downloads/custos_diebstahlschutz.mp4" className='video-style'></video>
            </div>
          </div> */}
        </div>
      </div>
      <div className="container container-width my-5">
        <div className="row shadow rounded-3 p-2 p-md-0">
          <div className="col-12 col-md-12 bg-light p-3 p-md-5 rounded-3">
            <div className="width-custom mx-auto">
              <h4 className='mb-4 mt-4 fw-semibold'>Wir verhelfen Ihnen zu Ihrem Recht</h4>
              <p>
                Wir unterstützen Versicherungsnehmer dabei, strittige <b>Ansprüche aus
                  Versicherungsverträgen</b> gegenüber den Versicherungsgesellschaften
                durchzusetzen. Dabei fokussieren wir uns auf die Sachversicherungssparten
                inkl. Ertragsausfall- und technische Versicherungen.
              </p>
            </div>
          </div>
        </div>

        <WhoAreWe className="row shadow mt-5 rounded-3">
          <div className="col-12 col-md-12 bg-transparent p-1 p-md-5 rounded-3">
            <div className="card card-body bg-primary width-compentence p-4 p-md-5">
              <div>
                <h5 className='mb-3 text-capitalize text-white fw-semibold'>KOMPETENZ UND ERFAHRUNG</h5>
                <p className='text-white'>
                  Unsere Juristen übernehmen die präzise und gerichtsfeste Recherche, Dokumentation und Formulierung des Sachverhaltes und
                  begründen den Anspruch schlüssig und unter Beachtung der Interessen der Versicherungsnehmer. So vorbereitet, machen wir die
                  Forderungen außergerichtlich gegenüber den Versicherungsgesellschaften geltend. Unser Ziel ist es, eine Einigung zwischen den
                  Parteien auf Augenhöhe zu erreichen. Sollte diese nicht erzielt werden können, erfolgt in Abstimmung mit den Auftraggebern eine
                  gerichtliche <b>Geltendmachung durch Kooperationsanwälte</b> aus unserem Netzwerk. Die professionelle Dokumentation ermöglicht
                  eine lückenlose Übergabe und einen zügigen Prozessbeginn, was fachliche Qualität und Effizienz sicherstellt
                </p>
              </div>
            </div>
          </div>
        </WhoAreWe>
      </div>
      <OurServices />
      <div className="container container-width my-5">
        <h5 className='text-center fw-normal'>Kontaktieren Sie uns für eine <b>kostenfreie</b> Einschätzung Ihrer aktuellen Situation.</h5>
        <Link to='/#contact' className={`btn btn-success text-white btn-md-lg d-table mx-auto mt-4`}>
          Gespräch vereinbaren
        </Link>
      </div>
      <div className="container container-width my-5">
        <div className="row shadow px-0">
          <div className="col-12 px-0">
            <div className="card card-body bg-light border-0 rounded-0 p-3 p-md-5 rounded-top">
              <h6 className='text-uppercase mb-4 text-primary'>EFFIZIENTE ANSPRUCHSDURCHSETZUNG</h6>
              <h4 className='width-custom mb-4 fw-bold'>Setzen Sie mit Custos Ihre (Rest-)forderungen zügig durch!</h4>
              <p className='mb-4'>
                Hier knüpft unser Dienstleistungsangebot an. Wir übernehmen die Aufarbeitung, Prüfung und Dokumentation des Sachverhaltes, die
                rechtliche Würdigung und übernehmen auf dieser Basis die außergerichtliche Geltendmachung der Ansprüche gegenüber den
                Versicherungsgesellschaften. Wir sind klar positioniert auf Versicherungsnehmerseite. Wir verfügen über eine Zulassung als
                Versicherungsberater gem. § 34 e GewO und beschäftigen erfahrene Volljuristen. Die Vergütung erfolgt in Anlehnung an das
                Rechtsanwaltsvergütungsgesetz und nach individueller Vereinbarung. Sofern eine gerichtliche Geltendmachung erforderlich wird, erfolgt
                diese durch Anwälte aus unserem Kooperationsnetzwerk auf Basis der von uns erstellten Dokumentation. Dies gewährleistet eine
                durchgängige Bearbeitung, fachliche Qualität und ermöglicht einen zügigen Prozessbeginn.
              </p>
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="card card-body bg-primary border-0 rounded-0 p-3 p-md-5 rounded-bottom">
              <h4 className='width-custom mb-4 mx-auto text-center text-white'>
                Sie möchten wissen, zu welchen Fragen der Entschädigungspflicht es
                unterschiedliche Meinungen gibt und welche Meinung wir vertreten?
                Dann lesen Sie......
              </h4>
              <div className='d-flex align-items-center justify-content-center gap-3'>
                <Link to='/blog' className={`btn btn-success mt-4 text-white`}>
                  Zum aktuellen Artikel
                </Link>
                <Link to='/blog' className={`btn btn-success mt-4 text-white`}>
                  Zur aktuellen Podcast-Folge
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container container-width my-5">
        <h4 className='text-center fw-semibold'>Sie haben viele Schadenfälle und Interesse an einer Kooperation?</h4>
        <p className='width-custom mx-auto text-center my-5'>
          Großunternehmen oder Makler haben eine Vielzahl von Schadenfällen. Wir bieten Kooperationsvereinbarungen zu
          interessanten wirtschaftlichen Konditionen an
        </p>
        <Link to='/#contact' className={`btn btn-success text-white d-table mx-auto mt-0`}>
          Kooperation anfragen
        </Link>
      </div>
      <div className="container container-width my-5">
        <div className="row shadow px-0">
          <div className="col-12 px-0">
            <div className="card card-body bg-light border-0 rounded-0 p-3 p-md-5 rounded-top">
              <h4 className='width-custom my-5 fw-bold'>Entwicklung des Sachversicherungsrechts</h4>
              <p className='mb-4'>
                Das innovative Angebot der Custos GmbH verfolgt neben den Vorteilen für die Versicherungsnehmer das Ziel, die Entwicklung des
                Sachversicherungsrechts zu fördern. Strittige Auffassungen, die sonst keiner Klärung zugeführt werden, werden entschieden. Das gibt
                Rechtssicherheit. Es soll die Grundlage geschaffen werden, dass die für Gerichtsverfahren in Art. 103 Abs. 1 GG vorgeschriebene
                Waffengleichheit erst ermöglicht wird. Verbunden wird das Angebot mit der Hoffnung, dass Versicherungsgesellschaften ihre
                Regulierungspolitik anpassen, wenn sie davon ausgehen müssen, dass sich die Masse der insbesondere kleineren Fälle nicht automatisch
                erledigt. Auch die Strategie der Rechtsprechungsvermeidung zur Vermeidung von ungünstigen Grundsatzentscheidungen könnte
                seitens der Versicherungsgesellschaften überdacht werden.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container container-width my-5">
        <h4 className='text-center fw-semibold mb-0'>Legal Tech</h4>
        <p className='width-custom mx-auto text-center my-4'>
          Wir arbeiten vollständig digital und bauen ein KI-gestütztes Wissensmanagement zur Optimierung der juristischen Tätigkeiten auf, so dass auch
          kleinere Schäden effizient, kostengünstig und zielführend bearbeitet werden können.
        </p>
        <p className='width-custom mx-auto text-center my-4'>
          Unsere Aufgabe als <b>Legal Tech</b> ist es, das im Unternehmen vorhandene Spezialwissen über eine Wissensdatenbank für KI-gestütze Anwendungen
          zur Verfügung zu stellen. Hierdurch soll die juristische Tätigkeit automatisiert sowie effizienter, zugänglicher und konsistenter gestaltet werden.
        </p>
        <p className='width-custom mx-auto text-center my-4'>
          Sprechen Sie uns an!
        </p>
        <Link to='/#contact' className={`btn btn-success text-white d-table mx-auto mt-0`}>
          Mehr erfahren
        </Link>
      </div>
    </>
  )
}

export default Home