import axe from "../../services/axios-client/axiosClient";
import caller from "./articleCaller";

export const fetchArticles = async (page = null) => {
  caller.getArticlesPending();

  try {
    let response = null;

    if (page === null) {
      response = await axe.get("/all-articles");
    }

    if (page !== null) {
      response = await axe.get("/all-articles", {
        params: {
          page: page,
        },
      });
    }

    if (response.status !== 200) {
      caller.getArticlesRejected("Internal Error");
    }

    if (response.status === 200) {
      caller.getArticlesSuccess(response.data.data);
    }
  } catch (error) {
    caller.getArticlesRejected("Internal Error");
  }
};

export const resetAllArticles = async () => {
  caller.resetArticles();
};

export const fetchArticle = async (slug) => {
  caller.getArticlePending();

  try {
    const response = await axe.get(`/article/${slug}`);

    if (response.status !== 200) {
      caller.getArticleRejected("Internal Error");
    }

    if (response.status === 200) {
      caller.getArticleSuccess(response.data);
    }
  } catch (error) {
    caller.getArticleRejected("Internal Error");
  }
};

export const fetchAuthorArticles = async (username, page = null) => {
  caller.getAuthorArticlesPending();

  try {
    let response = null;

    if (page === null) {
      response = await axe.get(`/author/${username}`);
    }

    if (page !== null) {
      response = await axe.get(`/author/${username}`, {
        params: {
          page: page,
        },
      });
    }

    if (response.status !== 200) {
      caller.getAuthorArticlesRejected("Internal Error");
    }

    if (response.status === 200) {
      caller.getAuthorArticlesSuccess(response.data);
    }
  } catch (error) {
    caller.getAuthorArticlesRejected("Internal Error");
  }
};

export const resetAllArticlesAuthor = async () => {
  caller.resetAuthorArticles();
};
