import React, { useEffect } from 'react'
import { HeaderCustomStyles } from './Custom.styles';

function Imprint() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [])

  return (
    <>
      <HeaderCustomStyles className="container-fluid px-0" />
      <div className='container container-width my-5'>
        <h2>Impressum</h2>
        <p>
          Custos GmbH <br />
          Sternstraße 7<br />
          59269 Beckum<br />
          <br />
          Telefon: +49 2521 82449-20<br />
          <br />
          E-Mail: info@custos.legal<br />
          Web: www.custos.legal<br />
          <br />
          Vertretung durch den Geschäftsführer Harald Vollgraf<br />
          Handelsregister: Amtsgericht Münster<br />
          Registernummer: HRB 7226<br />
          <br />
          Umsatzsteuer-ID-Nr.: DE 813856874<br />
          <br />
          <b>Zuständige IHK:</b><br />
          <br />
          IHK Nord Westfalen<br />
          Bezirkskammer Münster<br />
          Sentmaringer Weg 61<br />
          48151 Münster<br />
          <br />
          Tel. +49 (251) 707-0<br />
          Fax +49 (251) 707-325<br />
          <br />
          <b>Gewerbeamt:</b><br />
          <br />
          Stadt Beckum<br />
          Gewerbeamt<br />
          Weststraße 46<br />
          59269 Beckum<br />
          <br />
          <b>Zuständige Kammer:</b><br />
          <br />
          IHK Nordwestfalen<br />
          Postfach 4024, 48022 Münster<br />
          Telefon: +49 (251) 707-0<br />
          Fax: +49 (251) 707-325<br />
          Internetseite: www.ihk.nordwestfalen.de<br />
          E-Mail Anschrift: muenster@ihk-nordwestfalen.de<br />
          <br />
          <b>Registrierungsstelle:</b><br />
          <br />
          Deutscher Industrie- und Handelskammertag (DIHK) e.V.<br />
          Breite Straße 29, 10178 Berlin<br />
          Telefon: 0180-6005850 (20 Cent aus dem dt. Festnetz)<br />
          E-Mail Anschrift: infocenter@berlin.dihk.de<br />
          Internetseite: www.vermittlerregister.info<br />
          <br />
          <b>Beteiligungen:</b><br />
          <br />
          Unser Unternehmen hält keine direkte oder indirekte Beteiligung von über 10 % an den Stimmrechten oder am Kapital eines Versicherungsunternehmens. Umgekehrt hält auch kein Versicherungsunternehmen oder Mutterunternehmen eines Versicherungsunternehmens eine direkte oder indirekte Beteiligung von über 10 % an den Stimmrechten oder am Kapital unserer Gesellschaft.
          <br />
          <b>Schlichtungsstellen:</b><br />

          Ombudsmann für Versicherungen<br />
          Kronenstr. 13, 10117 Berlin<br />
          Telefon: 01804 / 224424<br />
          www.versicherungsombudsmann.de<br />

          Ombudsmann Private Kranken- und Pflegeversicherung<br />
          Kronenstr. 13, 10117 Berlin<br />
          Telefon: 01802 / 550444 (6 Cent pro Anruf aus dem dt. Festnetz)<br />
          www.pkv-ombudsmann.de<br />

          <b>Hinweis gem. Art. 14 Abs. 1 der EU-Verordnung (EU) Nr. 524/2013 (Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten):</b><br />

          Plattform der EU zur außergerichtlichen Online-Streitbeilegung https://ec.europa.eu/consumers/odr/<br />
          Wir sind weder bereit noch verpflichtet, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.<br />

          <b>Haftungsausschluss:</b><br />

          <b>Haftung für Inhalte</b><br />

          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Dienstanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Dienstanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br />

          <b>Haftung für Links</b><br />

          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
          <br />
          <b>Urheberrecht</b><br />

          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>
      </div>
    </>
  )
}

export default Imprint